import React from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faPhoneAlt, faMapMarked } from "@fortawesome/free-solid-svg-icons"
import { faEnvelope } from "@fortawesome/free-regular-svg-icons"
import { faInstagram, faFacebook } from "@fortawesome/free-brands-svg-icons"
import { graphql, Link, useStaticQuery } from "gatsby"
import footerStyles from "./footer.module.scss"

function Footer() {
  const data = useStaticQuery(graphql`
    query {
      image: file(relativePath: { eq: "je_logo.png" }) {
        id
        childImageSharp {
          fixed(width: 130) {
            ...GatsbyImageSharpFixed
          }
        }
      }
    }
  `)

  const myLogo = data?.image?.childImageSharp?.fixed?.src
  return (
    <footer className={footerStyles.container}>
      <div className={footerStyles.wrapper}>
        <div>
          <img src={myLogo} alt="joyous events logo" />
        </div>
        <div className={footerStyles.whatwedo}>
          <h3>What We Do</h3>
          <div className={footerStyles.content_wrapper}>
            <div className={footerStyles.content_row}>
              <Link to="/services">
                <p>Birthday Parties</p>
              </Link>
            </div>
            <div className={footerStyles.content_row}>
              <Link to="/services">
                <p>Gender Reveals</p>
              </Link>
            </div>
            <div className={footerStyles.content_row}>
              <Link to="/services">
                <p>Social Events</p>
              </Link>
            </div>
            <div className={footerStyles.content_row}>
              <Link to="/services">
                <p>And More...</p>
              </Link>
            </div>
          </div>
        </div>
        <div>
          <h3>Contact Us</h3>
          <div className={footerStyles.content_wrapper}>
            <div className={footerStyles.content_row}>
              <FontAwesomeIcon
                className={footerStyles.icon}
                icon={faMapMarked}
              />
              <p>DFW Metroplex</p>
            </div>
            <div className={footerStyles.content_row}>
              <FontAwesomeIcon
                className={footerStyles.icon}
                icon={faPhoneAlt}
              />
              <p>
                <Link to="tel:(972) 754 9746">(972) 754 9746</Link>
              </p>
            </div>

            <div className={footerStyles.content_row}>
              <FontAwesomeIcon
                className={footerStyles.icon}
                icon={faEnvelope}
              />
              <p>Ajoyevents@outlook.com</p>
            </div>
          </div>
        </div>
        <div>
          <h3>Follow Us</h3>
          <div className={footerStyles.content_row}>
            <a
              rel="noreferrer"
              target="_blank"
              href="https://www.instagram.com/joyous.events/?hl=en"
            >
              <FontAwesomeIcon
                className={footerStyles.icon}
                icon={faInstagram}
              />
            </a>
            <p>Instagram</p>
          </div>
          <div className={footerStyles.content_row}>
            <a
              rel="noreferrer"
              target="_blank"
              href="https://www.facebook.com/A.Joyous.events/"
            >
              <FontAwesomeIcon
                className={footerStyles.icon}
                icon={faFacebook}
              />
            </a>
            <p>FaceBook</p>
          </div>
        </div>
      </div>
      <div className={footerStyles.copy}>
        <div></div>© {new Date().getFullYear()} Joyous Events
        {` `}
        <p>
          Powered by <a href="/">Five Creature Designs</a>
        </p>
      </div>
    </footer>
  )
}

export default Footer
