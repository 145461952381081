import React from "react"
import PropTypes from "prop-types"
import { Link } from "gatsby"
import headerStyles from "./header.module.scss"
import Nav from "../nav/nav"
import { graphql, useStaticQuery } from "gatsby"

import RightNav from "../nav/right-nav"

function Header() {
  const data = useStaticQuery(graphql`
    query {
      image: file(relativePath: { eq: "je_logo.png" }) {
        id
        childImageSharp {
          fixed(width: 150) {
            ...GatsbyImageSharpFixed
          }
        }
      }
    }
  `)

  const myLogo = data?.image?.childImageSharp?.fixed?.src

  return (
    <header>
      <div className={headerStyles.wrapper}>
        <div className={headerStyles.container}>
          <Link to="/">
            <img src={myLogo}  />
          </Link>
          <Nav />
        </div>
        <RightNav />
      </div>
    </header>
  )
}

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
