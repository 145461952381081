import React, { useState } from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faFacebook, faInstagram } from "@fortawesome/free-brands-svg-icons"

import { Link } from "gatsby"

import navStyles from "./nav.module.scss"

function RightNav() {
  const [open, setOpen] = useState(false)

  return (
    <div className={navStyles.container}>
      <div onClick={() => setOpen(!open)} className={navStyles.hamburger_icon}>
        <div
          style={{
            transform: `${open ? "rotate(45deg)" : "rotate(0)"}`,
            transition: "all 0.3s linear",
          }}
          className={navStyles.hamburger_slices}
        ></div>
        <div
          style={{
            transform: `${open ? "translateX(100%)" : "translateX(0)"}`,
            opacity: `${open ? 0 : 1}`,
            transition: "all 0.3s linear",
          }}
          className={navStyles.hamburger_slices}
        ></div>
        <div
          style={{
            transform: `${open ? "rotate(-45deg)" : "rotate(0)"}`,
            transition: "all 0.3s linear",
          }}
          className={navStyles.hamburger_slices}
        ></div>
      </div>
      <ul
        className={navStyles.right_nav}
        style={{
          transform: `${open ? "translateX(0)" : " translateX(100%)"}`,
          transition: "all 0.3s linear",
        }}
      >
        <li>
          <Link to="/">Home</Link>
        </li>
        <li>
          <Link to="/about-us-page">About Us</Link>
        </li>
        <li>
          <Link to="/services">Services</Link>
        </li>

        <li>
          <Link to="/gallery-page">Gallery</Link>
        </li>
        <li>
          <Link to="/contact-us">Contact Us</Link>
        </li>
        <li className={navStyles.social_li}>
          <a
            rel="noreferrer"
            target="_blank"
            href="https://www.facebook.com/A.Joyous.events/"
          >
            <FontAwesomeIcon className={navStyles.social} icon={faFacebook} />
          </a>
        </li>
        <li>
          <a
            rel="noreferrer"
            target="_blank"
            to="https://www.instagram.com/joyous.events/?hl=en"
          >
            <FontAwesomeIcon className={navStyles.social} icon={faInstagram} />
          </a>
        </li>
      </ul>
    </div>
  )
}

export default RightNav
