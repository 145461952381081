import { Link } from "gatsby"
import React from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faFacebook, faInstagram } from "@fortawesome/free-brands-svg-icons"

import navStyles from "./nav.module.scss"

function Nav() {
  return (
    <div className={navStyles.container}>
      <ul className={navStyles.nav_list}>
        <li>
          <Link to="/">Home</Link>
        </li>
        <li>
          <Link to="/about-us-page">About Us</Link>
        </li>
        <li>
          <Link to="/services">Services</Link>
        </li>
        <li>
          <Link to="/gallery-page">Gallery</Link>
        </li>
        <li>
          <Link to="/contact-us">Contact Us</Link>
        </li>
        <li className={navStyles.social_li}>
          <a
            rel="noreferrer"
            target="_blank"
            href="https://www.facebook.com/A.Joyous.events/"
          >
            <FontAwesomeIcon className={navStyles.social} icon={faFacebook} />
          </a>
        </li>
        <li>
          <a
            rel="noreferrer"
            target="_blank"
            href="https://www.instagram.com/joyous.events/?hl=en"
          >
            <FontAwesomeIcon className={navStyles.social} icon={faInstagram} />
          </a>
        </li>
      </ul>
    </div>
  )
}

export default Nav
